<template>
  <div class="reg">
    <div class="reg-header">
      <topNav></topNav>
    </div>
    <div class="reg-main">
      <!-- <div class="m-steps">
        <Steps :current="curIndex">
          <Step title="Email verification"></Step>
          <Step title="Add your Profile"></Step>
          <Step title="Registration completed"></Step>
        </Steps>
      </div> -->

      <div class="admin-add">
        <div class="admin-add-content">
          <div style="text-align:center">You need to associate an e-mail</div>
          <Form class="F" ref="formInline" :model="formInline" :rules="ruleInline" @keyup.enter.native="handleSubmit('formInline')">
            <FormItem class="F-1" prop="email">
              <Input class="I" type="text" v-model="formInline.email" placeholder="Please enter your email" />
            </FormItem>
            <FormItem class="F-1 phone_code" prop="code">
              <Input class="I" type="text" ref="captcha_input2" maxlength="6" v-model="formInline.code" placeholder="Please enter the e-mail code" />
              <Button v-if="showCode" @click="getPhoneCode">Send</Button>
              <Button v-else disabled style="color: #ccc">{{ count }}s</Button>
            </FormItem>
            <Button type="primary" @click="handleSubmit('formInline')" size="large" class="btn">Next</Button>
          </Form>
        </div>
      </div>

      <!-- <iModal v-model="showModel" :mask-closable="false" @on-cancel="cancel" :styles="{ top: '200px' }" :footer-hide="true">
        <div class="model">
          <svg class="icon-svg icon-big" aria-hidden="true">
            <use xlink:href="#icon-j"></use>
          </svg>
          <p>
            {{ this.formInline.email }}
            <br />
            The email is already in use
            <br />
            Please confirm if it belongs to you
          </p>
          <div class="user-info">
            <img :src="userMsg.avatarUrl" v-if="userMsg.avatarUrl" />
            <img src="@/assets/img/empty.png" v-else />
            <span>{{ userMsg.nickName || userMsg.name }}</span>
          </div>
          <div class="bot-btn">
            <Button type="primary" @click="linkTo('no')">No. Keep registering</Button>
            <Button type="primary" @click="linkTo('yes')">Yes. sign in now</Button>
          </div>
        </div>
      </iModal> -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validator } from "@/other/validator";
import topNav from "./components/top";

export default {
  name: "reg",
  data() {
    return {
      curIndex: 0,
      formInline: {
        email: "",
        code: "",
      },
      ruleInline: {
        email: [
          { required: true, message: "Please add an e-mail", trigger: "blur" },
          {
            type: "email",
            message: "The email format is incorrect",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "Please add the e-mail code",
            trigger: "blur",
          },
        ],
      },
      showCode: true,
      count: "",
      codeKey: "",
      userMsg: "",
      showModel: false,
    };
  },
  components: {
    topNav,
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
    }),
  },
  methods: {
    ...mapActions({
      getImgCode: "loginModule/getImgCode",
      validateImgCode: "loginModule/validateImgCode",
      getMsgCode: "getMsgCode",
      validateCode: "validateCode",
      graphqlPost: "graphqlPostByZXS",
      linkToLogin: "linkTo/linkToLogin",
      getCustomForm: "getCustomForm",
      sendEamilCode: "en/sendEamilCode",
      linkToRegForm: "linkTo/linkToRegForm",
      linkToPassword: "linkTo/linkToPassword",
    }),
    cancel() {
      this.$refs["formInline"].resetFields();
      this.codeKey = "";
      this.formInline.jobTitle = "";
      this.formInline.name = "";
    },
    async getPhoneCode() {
      this.$refs.formInline.validateField("email", (val) => {
        if (val !== "") return;
        this.formInline.code = "";
        this.$refs.captcha_input2.focus();
        this.downTime();
      });
    },
    async downTime() {
      const valid = await this.sendEamilCode({ email: this.formInline.email });
      if (valid && valid.result) {
        this.codeKey = valid.data.key;
      }
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.showCode = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.showCode = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    async validMsgCode() {
      // 验证code
      if (!this.formInline.code) return false;
      let valid = await this.validateCode({
        key: this.codeKey,
        code: this.formInline.code,
      });
      if (!valid) {
        this.$Message.error("Validation failed!");
        return false;
      }
      return true;
    },
    validForm(name) {
      // 验证表单
      return new Promise((resolve, reject) => {
        this.$refs[name].validate((valid) => {
          resolve(valid);
        });
      });
    },
    async handleSubmit(name) {
      let valid = await this.validForm(name);
      if (!valid) return;
      let codeValid = await this.validMsgCode();
      if (!codeValid) return;
      this.linkTo();
      this.$store.commit("setForgetEmail", this.formInline.email);
    },
    linkTo() {
      this.linkToPassword();
      //   switch (type) {
      //     case "yes": // 是我的
      //       this.linkToLogin();
      //       break;
      //     case "no": // 不是我的
      //       let opt1 = {
      //         email: this.formInline.email,
      //         type: "no",
      //         edit: true,
      //       };
      //       this.linkToRegForm(opt1);
      //       break;
      //     case "next": // 不是我的
      //       let opt2 = {
      //         email: this.formInline.email,
      //         type: "next",
      //       };
      //       this.linkToRegForm(opt2);
      //       break;
      //   }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.reg {
  &-header {
    box-shadow: 0px 2px 3px 0px rgba(101, 101, 101, 0.1);
  }
  &-main {
    width: 1200px;
    margin: 50px auto;
    ::v-deep .m-steps {
      width: 710px;
      margin: 0 auto;
      .ivu-steps-item {
        .ivu-steps-head {
          background: transparent;
        }
      }
    }
  }
  .F {
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 380px;
    position: relative;
    margin: 0 auto;
    &-1 {
      margin-top: 34px;
      margin-bottom: 0;
      &.phone_code {
        ::v-deep {
          .ivu-form-item-content {
            display: flex;
            .ivu-btn {
              margin-left: 10px;
            }
          }
        }
      }
    }
    .F-2 {
      width: 100%;
      height: 15px;
      line-height: 15px;
      display: flex;
      justify-content: space-between;
      margin: 24px 0;
      .F-2-text {
        width: 48px;
        height: 13px;
        font-size: 12px;
        color: #333;
        cursor: pointer;
      }
    }
    .wx-btn {
      margin-top: 20px;
    }
    .code {
      width: 70px;
      height: 26px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 8px;
      cursor: pointer;
    }
    .btn {
      margin-top: 34px;
    }
  }
}
.model {
  width: 340px;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .icon-big {
    font-size: 100px;
    text-align: center;
    margin: 0 auto;
  }
  p {
    font-size: 16px;
    text-align: center;
    padding: 18px 0 34px;
    line-height: 2;
  }
  .user-info {
    width: 100%;
    height: 100px;
    background-color: #f7f7f7;
    border-radius: 10px;
    padding: 18px;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    img {
      width: 64px;
      height: 64px;
      display: block;
      margin-right: 14px;
      border-radius: 50%;
    }
    span {
      font-size: 16px;
    }
  }
  .bot-btn {
    display: flex;
    justify-content: space-between;
  }
}
</style>
